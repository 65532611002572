import React from 'react'
import {Card, Carousel} from "react-bootstrap"
import "./Sliderlarge.css"

function Sliderlarge() {
    return (
        
<div>
<div>
    <Carousel>
  <Carousel.Item >
    <img
      className="d-block w-100 opacity"
      src="slide.jpg"
      alt="First slide"
      
    />

   
    <Carousel.Caption >
      <h1>BECOME A DEALER</h1>
      <p>Exclusive policies and rules to encourage dealers to earn maximum profits and return on investment.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 opacity"
      src="slide.jpg"
      alt="Third slide"
    />

    <Carousel.Caption>
      <h1>DRIVING TOWARDS A GREENER FUTURE!</h1>
      <p>Kaptech India is focused on environment-friendly technologies to keep our planet greener and safer.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 opacity"
      src="slide.jpg"
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>TRANSFORMATIONAL AND SUSTAINABLE.</h3>
      <p>Innovation that is smooth, convenient, and affordable.</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel></div>

</div>
  );
  
}

export default Sliderlarge
