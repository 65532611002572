import React, { Component } from 'react'
import {Link, Switch} from 'react-router-dom';
import {Card} from 'react-bootstrap'
import firebase from '../../Config'

class Product extends Component{
    constructor(props){
        super(props);
        this.ref = firebase.firestore().collection("Product");
        this.unsubscribe =null;
        this.state ={
            products : [],
            value : this.props.match.params.id,
            NavigatorName:''
        };

    }
    componentDidMount(){
        console.log('id '+ this.state.value)
        this.unsubscribe= this.ref.onSnapshot(this.onCollectionUpdate);
    }
    onCollectionUpdate =(querySnapShot) =>{
        const products = [];
        querySnapShot.forEach((doc) =>{
            const {name, description,url,category,categoryName} =doc.data();
            products.push({
                key: doc.id,
                doc,
                name,
                description,
                url,
                category,
                categoryName


            });
        })
        this.setState({
            products
        })
        console.log(products)
        products.map(product => {
            if(product.category === this.state.value){
                if(product.categoryName){
                    this.setState({
                        NavigatorName:product.categoryName
 
                    })
                }
                return;
            }
        });
            
        
    }
    
    render(){ 

        if(this.state.value != 'all'){     
            return(
                <div>
                				<div className="navigation" style={{ textTransform: 'capitalize' }}>
					<div className="container">
						<ul>
							<a href="/">
								<li href="/">Home</li>
							</a>
							<li>
								<span> {'<'} </span>
							</li>
							<li>Product</li>
                            <li>
								<span> {'<'} </span>
							</li>
							<li>{this.state.NavigatorName}</li>

						</ul>
					</div>
				</div>
                    {this.state.products.map(product => product.category === this.state.value ? (
                        <div className="row" style={{marginTop:"10px"}}>
                            <div className="col-5"><img style={{width:"inherit"}}  src={product.url}></img></div>
                            <div className="col-6" style={{textAlign:'initial'}}>
                                <Link to = {`/productsView/${product.key}`}><h6>{product.name}</h6></Link> 
                                <p className="text-truncate line3">{product.description}</p>
                            </div>
                        </div>
                    ):( <div></div>)
                    )}
                </div>
       
            );  
        } else{
            return(
            <div>
            				<div className="navigation" style={{ textTransform: 'capitalize' }}>
					<div className="container">
						<ul>
							<a href="/">
								<li href="/">Home</li>
							</a>
							<li>
								<span> {'<'} </span>
							</li>
							<li>Product</li>

							<li>
								<span> {'<'} </span>
							</li>
							{/* <a href={'/products/' + this.state.product.category}>
								{' '}
								<li>{this.state.product.categoryName}</li>
							</a>
							<li>
								<span> {'<'} </span>
							</li>
							<li>{this.state.product.name}</li> */}
						</ul>
					</div>
				</div>
            {this.state.products.map(product => (
                <div className="row" style={{marginTop:"10px"}}>
                    <div className="col-5"><img width="200px"  src={product.url}></img></div>
                    <div className="col-6" style={{textAlign:'initial'}}>
                        <Link to = {`/productsView/${product.key}`}><h6>{product.name}</h6></Link> 
                        <p>{product.description}</p>
                    </div>
                </div>
            )
            )}
            </div>
            )

        }
    
    }
}


export default Product
