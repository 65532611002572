import firebase from 'firebase'
import 'firebase/firestore'

const settings = {timestampsInSnapshots: true}

var firebaseConfig = {
    apiKey: "AIzaSyB65ISM8xqHzWUDwLa7xLdTsaoZo9Bh-f8",
    authDomain: "full-database.firebaseapp.com",
    projectId: "full-database",
    storageBucket: "full-database.appspot.com",
    messagingSenderId: "575522423597",
    appId: "1:575522423597:web:9b06b57fb8a2ef86fdc9a4"
  };
  //initialize firebase
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings(settings);

  export default firebase;