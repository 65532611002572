import React,{ Component } from "react";
import './App.css';
import {BrowserRouter as Router,Switch,Route}
from "react-router-dom"

import './App.css'

import "bootstrap/dist/css/bootstrap.min.css";

import Home from './Home/Home';
import Footer from './Home/Footer';
import Header from './Home/Header';
import Aboutus from './Home/Aboutus/Aboutus';
import Contactus from "./Home/Contactus/Contactus";
import Product from './Home/Product/Product'
import ProductView from "./Home/Product/ProductView";


function App() {
  return (
    <Router> 
        <div className="App">


<Switch>

<Route path="/contactus">
       <Header></Header>
       <Contactus></Contactus>
       <Footer></Footer>
    </Route>

    <Route path="/aboutus">
       <Header></Header>
       <Aboutus></Aboutus>
       <Footer></Footer>
    </Route>

    <Route exact path='/' >
      <Home></Home>
      <Footer></Footer>
    </Route>
    <Route path="/products/:id" render={(props)=>(<div>   <Header></Header>
     <Product {...props} ></Product>
      <Footer></Footer></div>)}></Route>

      <Route path="/productsView/:id" render={(props)=>(<div>   <Header></Header>
     <ProductView {...props} ></ProductView>
      <Footer></Footer></div>)}></Route>

      </Switch>
       </div>
      
      </Router>

  );
}

export default App;
