import React from 'react'
import './Homecontent.css'

function Homecontent() {
    return (
      
             <div className="row content1" >
            <div className="col-xs-6">
               <img className="img1" src="contentbody.jpg"  />
            </div>
           <div className="col-xs-6">
               <div className="container1">  
               <h3 className="heading">About Us</h3>
        <p className="ptag">XYZ ROBOTICS is a brand , A company owned by Chennai (Tamil Nadu) based young and dynamic entrepreneurs. While thinking big they started small. The promoters incorporated the company in 2014 with strong belief in the future of electric vehicles industry. With a strong product line and growing dealer network the company now stands as one of the most promising player in electric vehicles industry and electric rickshaw spare parts<br></br> <br></br>

Our major products include i-CAT (International Centre for Automotive Technology) approved e-rickshaws, two wheelers and spare parts for electric vehicles. With growing concerns for air pollution, there is huge demand for our revolutionary electric rickshaws. This presents a good business opportunity for those who want to open e rickshaw dealership. The company has a strong dealer network in east India and plans to expand pan India.<br></br> <br></br>

Our electric rickshaws are known for latest advanced features, reliability and mileage. Our company is committed to make the most ecofriendly green electric two wheelers and e-rickshaws.</p> </div>
            </div>
        </div>
      
    )
}

export default Homecontent
