import React from 'react'
import './Contactus.css'

function Contactus() {
    return (
        
    <div>
    <div className="navigation">
        <div className="container">
           <ul>
           <a href="/">
          <li href="/">Home</li></a>
               <li><span> {"<"} </span></li>
               <li>Contact us</li>
           </ul>
        </div>
    </div>


    <div class="row content1" >
      <div class="col-sm-4">
         <div class="wrapper">
            <span class="fa-stack fa-5x">
               <i class="fa fa-circle fa-stack-2x"></i>
               <i class="fa fas fa-map-marker-alt fa-stack-1x fa-inverse"></i>
          </span>
          <p> No:3 Santhosh Nagar 1st street,<br/> Puthagaram ,Kolathur,<br/> Chennai-600099 </p>

         </div>
      </div>
     <div class="col-sm-4">
       <div class="wrapper">
          <span class="fa-stack fa-5x">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa far fa-envelope fa-stack-1x fa-inverse"></i>
          </span>
          <p><a href="mailto:xyz.customercare@gmail.com">xyz.customercare@gmail.com</a><br/><a href="mailto:xyzrobotics@yahoo.com">xyzrobotics@yahoo.com</a></p>
        </div>
      </div>

      <div class="col-sm-4">
       <div class="wrapper">
          <span class="fa-stack fa-5x">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fas fa-mobile-alt fa-stack-1x fa-inverse"></i>
          </span>
          <p> 
          +91 9841895786
           </p>
        </div>
      </div>
    </div>

    <div className="row map-container">         
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d401330
    0.8627840024!2d78.28835166834617!3d10.792988836785819!2m3!1f0!2f0!3f0!3m2!
    1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52619dfe78eb53%3A0x597d25ec90ae363e!2sXY
    Z%20Robotics%20e-rickshaw%20and%20e-loaders%20Manufacturer%20And%20E-Ricksh
    aw%20Spare%20Parts%20In%20Chennai!5e0!3m2!1sen!2sin!4v1606407719788!5m2!1sen
    !2sin"  height="450" ></iframe>
     </div>

  </div>

 


        
    )
}

export default Contactus
