import React from 'react'
import './Footer.css';
function Footer() {
    return (<div className="footer">
    <div className='container'>
    <div className='row'>
    <div class="col-md-3 col-sm-6 text-center text-sm-left">
							<div class="widget widget_text">
								<h4 class="widget-title highlight">Contact Us</h4>
                                <table class="highlightlinks">
									<tbody>
										<tr>
											<td>Corporate Office:</td>
											<td> No:3 Santhosh Nagar, 1st street,<br/>
                                            Puthagaram, Kolathur,<br/>
                                            Chennai-600099</td>
										</tr>
										<tr>
											<td>Phone:</td>
											<td>+91 9841895786</td>
										</tr>
										<tr>
											<td>Email:</td>
											<td><a href="mailto:xyz.customercare@gmail.com">xyz.customercare@gmail.com</a><br/><a href="mailto:xyzrobotics@yahoo.com">xyzrobotics@yahoo.com</a></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
                        <div class=" col-sm-6 text-center text-sm-left paddTop">
							<div class="widget widget_text">
								<h4 class="widget-title highlight20"></h4>
								<table class="highlightlinks">
									<tbody>
										<tr>
											<td>Factory Office:</td>
											<td>No:7,8 Abdul Kalam Nagar masjid street <br/>
                                            GNT Road Sholavaram <br/>
											Chennai-600067</td>
										</tr>
										
									</tbody>
								</table>
							</div>
						</div>
    </div>
    
    </div>
        <div className="footer-content">
            <ul className="socials">
                <li><a href="#"><i class="fab fa-facebook-f fa-3x"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter fa-3x"></i></a></li>
                <li><a href="https://www.instagram.com/xyz_robotics_/"><i class="fab fa-instagram fa-3x"></i></a></li>
                <li><a href=""><i class="fab fa-linkedin fa-3x"></i></a></li>


            </ul>
        </div>
        <div className="footer-bottom">
        <p>copyright &copy;2020 XYZ ROBOTICS.<br/>Designed by <a href="#">YASAR</a></p>

        </div>
        </div>


    )
}

export default Footer
