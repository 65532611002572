import React from 'react'
import {Navbar,Nav,Form,FormControl,Button ,NavDropdown} from 'react-bootstrap'
import { Link } from 'react-router-dom';


import './Header.css';
function Header() { 
  
  function myFunction(id) {
    if(window.innerWidth > 991){
      return;
    }
    console.log(window.innerWidth);
    console.log(id);  
    var element = document.getElementById(id);
    element.classList.toggle("show");
  }
  return (
    <Navbar bg="light" expand="lg" >
        <Link to="/">
          <Navbar.Brand >	<img
                    src='https://firebasestorage.googleapis.com/v0/b/full-database.appspot.com/o/logo-header1.png?alt=media&token=0ace787b-8ac3-4171-ad65-40a8459f'
                    width="137"
                    height="97"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav style={{flexFlow:'wrap'}}   className="m-auto  text-uppercase" >
            <Nav.Link className="ml-3 hover-color" href="/">Home</Nav.Link>    
            <li class="nav-item dropdown ml-3 dropdown1 clearPadding">
                      <a class="nav-link dropdown-toggle"  onClick={() => myFunction('show1')} id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                      E-BICYCLE KIT
                      </a>
                    <ul class="dropdown-menu dropdown-menu1 showNone" aria-labelledby="navbarDropdownMenuLink" id="show1">
                      <li><a class="dropdown-item  "  href="/products/cyclekitsidemotor">CYCLE KIT SIDE MOTOR</a>
                      </li>
                      <li><a class="dropdown-item" href="/products/hubmotorkit">HUB MOTOR KIT</a>
                      </li>
                    </ul>
            </li> 

            <li class="nav-item dropdown ml-3 dropdown1 clearPadding">
                      <a class="nav-link dropdown-toggle"  onClick={() => myFunction('show2')} id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                      E-SCOOTER KIT
                      </a>
                    <ul class="dropdown-menu dropdown-menu1 showNone" aria-labelledby="navbarDropdownMenuLink" id="show2">
                      <li><a class="dropdown-item  "  href="/products/gearmotorkit">GEAR MOTOR KIT</a>
                      </li>
                      <li><a class="dropdown-item" href="/products/10inchhubmotorkit">10'INCH HUB MOTOR KITT</a>
                      </li>
                      <li><a class="dropdown-item" href="/products/12inchhubmotorkit">12'INCH HUB MOTOR KITT</a>
                      </li>
                      <li><a class="dropdown-item" href="/products/17inchhubmotorkit">17'INCH HUB MOTOR KITT</a>
                      </li>
                    </ul>
            </li> 

            <li class="nav-item dropdown ml-3 dropdown1 clearPadding">
                      <a class="nav-link dropdown-toggle"  onClick={() => myFunction('show3')} id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                      E-RICKSHW
                      </a>
                    <ul class="dropdown-menu dropdown-menu1 showNone" aria-labelledby="navbarDropdownMenuLink" id="show3">
                      <li><a class="dropdown-item  "  href="/products/epassenger">E-RICKSHAW PASSANGER</a>
                      </li>
                      <li><a class="dropdown-item" href="/products/eloader">E-LOADER</a>
                      </li>
                      <li><a class="dropdown-item" href="/products/ecart">E-VEGETABLE CART</a>
                      </li>
                      <li><a class="dropdown-item" href="/products/egas">E-GAS LOADER</a>
                      </li>
                      <li><a class="dropdown-item" href="/products/egarbage">E-GARBAGE</a>
                      </li>
                    </ul>
            </li> 




            <li class="nav-item dropdown ml-3 dropdown1 clearPadding">
                      <a class="nav-link dropdown-toggle"  onClick={() => myFunction('show4')} id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  href="#/products/all">
                      E-RICKSHAW SPARE PARTS
                      </a>
                    <ul class="dropdown-menu dropdown-menu1 showNone" aria-labelledby="navbarDropdownMenuLink" id="show4">
                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show5')} href="#">MOTOR</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show5">
                        <li><a class="dropdown-item" href="/products/48v1000wmotor">48V 1000W MOTOR</a></li>
                        <li><a class="dropdown-item" href="/products/48v1200wmotor">48V 1200W MOTOR</a></li>
                        <li><a class="dropdown-item" href="/products/48v1500wmotor">48V 1500W MOTOR</a></li>
                        <li><a class="dropdown-item" href="/products/60v1000wmotor">60V 1000W MOTOR</a></li>
                        <li><a class="dropdown-item" href="/products/60v1200wmotor">60V 1200W MOTOR</a></li>

                        </ul>
                      </li>
                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show6')} href="#">CONTROLLER</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show6">
                        <li><a class="dropdown-item" href="/products/36vcontroller">36V CONTROLLER</a></li>
                        <li><a class="dropdown-item" href="/products/48v50acontroller">48V 50A CONTROLLER</a></li>
                        <li><a class="dropdown-item" href="/products/60v50acontroller">60V 50A CONTROLLER</a></li>
                        </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show7')} href="#">HYDROLIC SHOCKER SET</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show7">
                        <li><a class="dropdown-item" href="/products/31mmhydrolicshockerwithoutspring">31MM HYDROLIC SHOCKER WITHOUT SPRING</a></li>
                        <li><a class="dropdown-item" href="/products/31mmhydrolicshockerwithspring">31MM HYDROLIC SHOCKER WITH SPRING</a></li>
                        <li><a class="dropdown-item" href="/products/43mmhydrolicshockerwithspring">43MM HYDROLIC SHOCKER WITH SPRING</a></li>

                        </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show8')} href="#">DIFFERENTIAL </a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show8">
                        <li><a class="dropdown-item" href="/products/33inchdifferential">33'INCH DIFFERENTIAL</a></li>
                        <li><a class="dropdown-item" href="/products/35inchdifferential">35'INCH DIFFERENTIAL</a></li>
                        <li><a class="dropdown-item" href="/products/42inchdifferential">42'INCH DIFFERENTIAL</a></li>
                        </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show9')} href="#">TYRE </a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show9">
                        <li><a class="dropdown-item" href="/products/909012tyre">90:90/12 TYRE</a></li>
                        <li><a class="dropdown-item" href="/products/37512tyre">3.75/12 TYRE</a></li>
                        </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show10')} href="#">TUBE </a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show10">
                        <li><a class="dropdown-item" href="/products/909012tube">90:90/12 TUBE</a></li>
                        <li><a class="dropdown-item" href="/products/37512tube">3.75/12 TUBE</a></li>
                        </ul>
                      </li>

                      
                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show10')} href="#">RIM </a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show10">
                        <li><a class="dropdown-item" href="/products/909012rim">90:90/12 RIM</a></li>
                        <li><a class="dropdown-item" href="/products/37512rim">3.75/12 RIM(HEAVY)</a></li>
                        </ul>
                      </li>

                      
                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show11')} href="#">TEE-HANDLE </a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show11">
                        <li><a class="dropdown-item" href="/products/31mmteehandle">31MM TEE-HANDLE</a></li>
                        <li><a class="dropdown-item" href="/products/43mmteehandle">43MM TEE-HANDLE</a></li>                        </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show12')} href="#">KAAN SET</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show12">
                        <li><a class="dropdown-item" href="/products/31mmkanset">31MM KAAN SET</a></li>
                        <li><a class="dropdown-item" href="/products/43mmkanset">43MM KAAN SET</a></li>                        </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show13')} href="#">BEARING</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show13">
                        <li><a class="dropdown-item" href="/products/bearing30205">BEARING 30205</a></li>
                        <li><a class="dropdown-item" href="/products/bearing6201">BEARING 6201</a></li>
                        <li><a class="dropdown-item" href="/products/bearing6205">BEARING 6205</a></li>
                                               </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show14')} href="#">FRONT BRAKE HUB </a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show14">
                        <li><a class="dropdown-item" href="/products/12mmhubassy ">12MM HUB ASSY </a></li>
                        <li><a class="dropdown-item" href="/products/15mmhubassy ">15MM HUB ASSY </a></li>

                                               </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show14')} href="#">MUDGARD</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show14">
                        <li><a class="dropdown-item" href="/products/31mmmudgard ">31MM MUDGARD </a></li>
                        <li><a class="dropdown-item" href="/products/43mmmudgard ">43MM MUDGARD </a></li>

                                               </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show15')} href="#">SPEEDOMETER</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show15">
                        <li><a class="dropdown-item" href="/products/speedometerlcd ">SPEEDOMETER (LCD)</a></li>
                        <li><a class="dropdown-item" href="/products/speedometeranalog">SPEEDOMETER (ANALOG) </a></li>

                                               </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show16')} href="#">HEADLIGHT</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show16">
                        <li><a class="dropdown-item" href="/products/headlightled">HEADLIGHT (LED)</a></li>
                        <li><a class="dropdown-item" href="/products/headlightbulbtype">HEADLIGHT (BULB TYPE)</a></li>

                                               </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show17')} href="#">THROTTLE</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show17">
                        <li><a class="dropdown-item" href="/products/throttlrset">THROTTLR SET F/R</a></li>
                        <li><a class="dropdown-item" href="/products/footthrottlrset">FOOT THROTTLE</a></li>
                        <li><a class="dropdown-item" href="/products/throttlrsetbat">THROTTLE WITH BATTERY INDICATOR & ON/OFF KEY 48V</a></li>

                                               </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show18')} href="#">LEAF SPRING ( KAMMANI )</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show18">
                        <li><a class="dropdown-item" href="/products/45mmkammaniset">45MM KAMMANI SET</a></li>
                        <li><a class="dropdown-item" href="/products/ubolt">U-BOLT WITH LOCKNUT</a></li>
                        <li><a class="dropdown-item" href="/products/zhulla">ZHULLA</a></li>
                        <li><a class="dropdown-item" href="/products/leafspringbush">LEAF SPRING BUSH</a></li>


                                               </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show19')} href="#">BRAKE SHOE</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show19">
                        <li><a class="dropdown-item" href="/products/160mmbrakeset">160MM BRAKE SHOE</a></li>
                        <li><a class="dropdown-item" href="/products/180mmbrakeset">180MM BRAKE SHOE</a></li>
                                               </ul>
                      </li>

                      
                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show20')} href="#">CONVERTER</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show20">
                        <li><a class="dropdown-item" href="/products/10ampconv">48/60/72V 10AMP CONVERTER</a></li>
                        <li><a class="dropdown-item" href="/products/15ampconv">48/60/72V 15AMP CONVERTER</a></li>
                        <li><a class="dropdown-item" href="/products/20ampconv">48/60/72V 20AMP CONVERTER</a></li>
                                               </ul>
                      </li>

                                   
                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show21')} href="#">WIRING KIT</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show21">
                        <li><a class="dropdown-item" href="/products/8mmwiringkit">8MM WIRING KIT</a></li>
                        <li><a class="dropdown-item" href="/products/10mmwiringkit">10MM WIRING KIT( HEAVY )</a></li>

                                               </ul>
                      </li>
                      <li><a class="dropdown-item" href="/products/handlebar">HANDLE BAR</a></li>
                      <li><a class="dropdown-item" href="/products/leftRightSwitch">LEFT/RIGHT SWITCH</a></li>
                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle  " onClick={() => myFunction('show22')} href="#">FRONT HORN</a>
                        <ul class="dropdown-menu dropdown-menu2  positionAdjust " id="show22">
                        <li><a class="dropdown-item" href="/products/fronthorn12v"> FRONT HORN 12V</a></li>
                        <li><a class="dropdown-item" href="/products/fronthorn48v"> FRONT HORN 48V</a></li>

                                               </ul>
                      </li>
                      <li><a class="dropdown-item" href="/products/mcb63amp">MCB 63AMP</a></li>
                          <li><a class="dropdown-item" href="/products/frontindicator">FRONT INDICATOR</a></li>
                          <li><a class="dropdown-item" href="/products/backtailliight">BACK TAIL LIGHT</a></li>
                          <li><a class="dropdown-item" href="/products/yelowbox">YELLOW BOX</a></li>
                          <li><a class="dropdown-item" href="/products/relay">RELAY</a></li>
                          <li><a class="dropdown-item" href="/products/brakeswitch">BRAKE SWITCH</a></li>
                          <li><a class="dropdown-item" href="/products/meterwire">METER WIRE</a></li>
                          <li><a class="dropdown-item" href="/products/adrodpin">ADJUSTING ROD & PIN</a></li>
                          <li><a class="dropdown-item" href="/products/brpaddle">BRAKE PADDLE WITH SPRING</a></li>
                          <li><a class="dropdown-item" href="/products/frbrake">FRONT BRAKE WIRE</a></li>
                          <li><a class="dropdown-item" href="/products/onOffSwitch">ON/OFF KEY </a></li>
                          <li><a class="dropdown-item" href="/products/handbrwire">HAND BREAK WITH WIRE</a></li>
                          <li><a class="dropdown-item" href="/products/rearmirror">REAR VIEW MIRROR</a></li>
                          <li><a class="dropdown-item" href="/products/passhandle">PASSENGER HANDLE</a></li>
                          <li><a class="dropdown-item" href="/products/buzfls">BUZZER / FLASHER</a></li>  
                          <li><a class="dropdown-item" href="/products/chrsocket">CHARGING SOCKET</a></li>
                          <li><a class="dropdown-item" href="/products/rearshocker">REAR SHOCKER</a></li>






                      {/* <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle" onClick={() => myFunction('show4')} href="#">E Rickshaw Spare Parts</a>
                        <ul class="dropdown-menu dropdown-menu2 " id="show4">
                          
                        <li><a class="dropdown-item" href="/products/accessories">Accessories</a></li>
                          <li><a class="dropdown-item" href="/products/bikeparts">Brake Parts</a></li>
                          <li><a class="dropdown-item" href="/products/charger">Charger</a></li>
                          <li><a class="dropdown-item" href="/products/Controller">Controller</a></li>
                          <li><a class="dropdown-item" href="/products/differential">Differential</a></li>
                          <li><a class="dropdown-item" href="/products/hardwarefittings">Hardware Fittings</a></li>
                          <li><a class="dropdown-item" href="/products/leafSpring">Leaf Spring</a></li>
                          <li><a class="dropdown-item" href="/products/motor">Motor</a></li>
                          <li><a class="dropdown-item" href="/products/rims">Rims</a></li>
                          <li><a class="dropdown-item" href="/products/tubes">Tubes</a></li>
                          <li><a class="dropdown-item" href="/products/tyres">Tyres</a></li>

                          <li class="dropdown-submenu dropdown3"><a class="dropdown-item dropdown-toggle toggle1" onClick={() => myFunction('show5')} href="#">Front Assembly</a>
                            <ul class="dropdown-menu dropdown-menu3 showNone" id="show5">
                              <li><a class="dropdown-item" href="/products/bearing">Bearing</a></li>
                              <li><a class="dropdown-item" href="/products/brakeHub">Brake Hub</a></li>
                              <li><a class="dropdown-item" href="/products/frontAxle">Front Axle</a></li>
                              <li><a class="dropdown-item" href="/products/lightEar">Light Ear</a></li>
                              <li><a class="dropdown-item" href="/products/mudguard">Mudguard</a></li>
                              <li><a class="dropdown-item" href="/products/shockers">Shockers</a></li>
                              <li><a class="dropdown-item" href="/products/tee">Tee</a></li>

                            </ul>
                          </li>      
                        </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle" onClick={() => myFunction('show7')} href="#">Electrical-Parts</a>
                        <ul class="dropdown-menu dropdown-menu2 " id="show7">
                        <li><a class="dropdown-item" href="/products/backlight">Backlight</a></li>
                          <li><a class="dropdown-item" href="/products/centralLocks">Central Locks</a></li>
                          <li><a class="dropdown-item" href="/products/Converter">Converter</a></li>
                          <li><a class="dropdown-item" href="/products/harness">Harness</a></li>
                          <li><a class="dropdown-item" href="/products/headlights">Headlights</a></li>
                          <li><a class="dropdown-item" href="/products/Horn">Horn</a></li>
                          <li><a class="dropdown-item" href="/products/indicator">Indicator</a></li>
                          <li><a class="dropdown-item" href="/products/leftRightSwitch">Left/Right Switch</a></li>
                          <li><a class="dropdown-item" href="/products/meter">Meter</a></li>
                          <li><a class="dropdown-item" href="/products/onOffSwitch">On/Off Switch</a></li>

                        </ul>
                      </li>

                      <li class="dropdown-submenu dropdown2"><a class="dropdown-item dropdown-toggle" onClick={() => myFunction('show8')} href="#">Body Parts</a>
                        <ul class="dropdown-menu dropdown-menu2 " id="show8">
                        <li><a class="dropdown-item" href="/products/bajajTypeFront">Bajaj Type Front</a></li>
                          <li><a class="dropdown-item" href="/products/butterfly">Butterfly</a></li>
                          <li><a class="dropdown-item" href="/products/bodyConverter">Converter</a></li>
                          <li><a class="dropdown-item" href="/products/dashboard">Dashboard</a></li>
                          <li><a class="dropdown-item" href="/products/driverSeatBox">Driver Seat Box</a></li>
                          <li><a class="dropdown-item" href="/products/windscreen">Windscreen</a></li>

                        </ul>
                      </li> */}

                    </ul>
            </li> 
                  
                <Nav.Link className="ml-3 hover-color" href="/aboutus">About us</Nav.Link>
                <Nav.Link className="ml-3 hover-color" href="/contactus">Contact</Nav.Link>
          </Nav>
            
        </Navbar.Collapse>
      </Navbar>

    
    )
}

export default Header
