import React from 'react'
import Carousel from "react-elastic-carousel"
import './Slidersmall.css'


const Slide=({url1})=><div className="card">
<img
      className="d-block w-100 "
      src={url1}
      alt="First slide"
     
    /></div>
function Slidesmall() {

    const breakPoints =[
        {width:500, itemsToShow:1},
        {width:786, itemsToShow:2},
        {width:1200, itemsToShow:4},
        {width:1500, itemsToShow:5},
      
      ]
      
          return (
      
      <div>
        <h1>Feautred products</h1>
        <Carousel breakPoints={breakPoints}>
      
          <Slide url1="smallslide1.jpg"/>
          <Slide  url1="smallslide2.png"/>
          <Slide  url1="smallslide3.png"/>
          <Slide   url1="smallslide1.jpg"/>
          <Slide   url1="smallslide2.png"/>
        </Carousel>
      </div>
      
        );
      }
      

export default Slidesmall
