import React from 'react'
import {BrowserRouter as Router,Switch,Route}
from "react-router-dom"
import Footer from './Footer';
import Sliderlarge from './Sliderlarge'
import './Home.css'

import "bootstrap/dist/css/bootstrap.min.css";
import Header from './Header';
import Slidersmall from './Slidersmall';
import Homecontent from './Homecontent';

function Home() {



    return (
    <Router>
    <div>
        <Header></Header>
        <Sliderlarge></Sliderlarge>
        <div className='row flex-row' style={{height:'',maxWidth:'100%',margin:'0'}}>
            <div className='col-lg-4 col-md-6 col-sm-12 hoverin' style={{background:'#016FCC',    padding: '27px',height:'146px'}}>
            <div style={{width:'100%',height:'100%',    display:'inline-flex'}} >

            <div style={{width:'109px',background:'white',height:'100%',borderRadius:'50%'}} >
            <img src="http://i.stack.imgur.com/nGbfO.png" className='hoverInr' style={{width: '29%', height: '42%', color: 'white',marginTop:'25px'}}/>

            </div>
            <span className='textSpan'  style={{width:'min-content',bottom:'25%'}}>COST EFFICIENT</span>
            </div>
            {/* <a>
            <img src="http://i.stack.imgur.com/nGbfO.png" width="8" height="10" style={{color:'white'}}/>
            </a> */}
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 hoverin' style={{background:'#82CC33',height:'146px',padding: '27px'}}>
            <div style={{width:'100%',height:'100%',    display:'inline-flex'}} >
            <div style={{width:'109px',background:'white',height:'100%',borderRadius:'50%'}} >
            <i class="fa fa-leaf fa-2x" aria-hidden="true" style={{padding:'31px'  }} ></i>

            </div>
            <span className='textSpan' style={{width:'min-content',bottom:'25%'}}>ENVIRONMENT FRIENDLY</span>

            </div>

            </div>
            <div className='col-lg-4 col-md-12 col-sm-12 hoverin'style={{background:'#D1C500',height:'146px' ,   padding: '27px'}}>
            <div style={{width:'100%',height:'100%',    display:'inline-flex'}} >
            <div style={{width:'109px',height:'100%',background:'white',borderRadius:'50%'}}>
            <i class="fa fa-crosshairs fa-2x" aria-hidden="true" style={{ padding:'31px'}} ></i>

            </div>
            <span className='textSpan' style={{width:'min-content',bottom:'25%'}}>BUILD QUALITY</span>


            </div>
            {/* <span className='textSpan' style={{width:'min-content',bottom:'25%',right: '74px'}}>QUALITY ASSURANCE</span> */}

           
            </div>


        </div>        
       
      {/* <Homecontent></Homecontent> */}

       <span><Homecontent></Homecontent></span>
       <Slidersmall></Slidersmall>
     
    </div>
    
       
    </Router>
 
    )
}

export default Home
