import React from 'react';
import firebase from '../../Config';
import 'firebase/storage';
import './ProductView.css';

class ProductView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			product: [],
			key: '',
			style1: {
				backgroundPosition: '0% 0%',
				width: '100%',
			},
		};
	}
	componentDidMount() {
		const ref = firebase.firestore().collection('Product').doc(this.props.match.params.id);
		console.log('ii' + this.props.match.params.id);
		ref.get().then((doc) => {
			if (doc.exists) {
				this.setState({
					product: doc.data(),
					key: doc.id,
					isLoading: false,
					setImage: doc.data().url,
				});
				console.log(doc.data());
			} else {
				console.log('no  such  documrnt here');
			}
		});
	}
	imgClick(id) {
		this.setState({
			setImage: id,
		});
		console.log(id);
	}
	handleMouseMove = (e) => {
		console.log(this.state.setImage);
		const { left, top, width, height } = e.target.getBoundingClientRect();
		const x = ((e.pageX - left) / width) * 100;
		const y = ((e.pageY - top) / height) * 100;
		this.setState({
			style1: {
				...this.state.style1,
				backgroundPosition: `${x}% ${y}%`,
				backgroundImage: `url('${this.state.setImage}')`,
			},
		});
		console.log(this.state.style1.backgroundImage);
	};

	render() {
		return (
			<div className="row" style={{ width: '100%', margin: '0px' }}>
				<div className="navigation" style={{ textTransform: 'capitalize' }}>
					<div className="container">
						<ul>
							<a href="/">
								<li href="/">Home</li>
							</a>
							<li>
								<span> {'<'} </span>
							</li>
							<li>Product</li>

							<li>
								<span> {'<'} </span>
							</li>
							<a href={'/products/' + this.state.product.category}>
								{' '}
								<li>{this.state.product.categoryName}</li>
							</a>
							<li>
								<span> {'<'} </span>
							</li>
							<li>{this.state.product.name}</li>
						</ul>
					</div>
				</div>
<div style={{display:'flex',flexWrap:'wrap'}}>
				<div className="width-25">
					<div style={{ width: '100%', height: '100%' }}>
						<img
							className="side-img"
							src={this.state.product.url}
							onClick={() => this.imgClick(this.state.product.url)}
							alt=""
							width="100%"
						/>
						<img
							className="side-img"
							src={this.state.product.url1}
							onClick={() => this.imgClick(this.state.product.url1)}
							alt=""
							width="100%"
						/>
						<img
							className="side-img"
							src={this.state.product.url2}
							onClick={() => this.imgClick(this.state.product.url2)}
							alt=""
							width="100%"
						/>
					</div>
				</div>
				<div className="width-30">
					<div style={{ width: '100%', height: '100%', paddingTop: '27%' }}>
						<figure onMouseMove={this.handleMouseMove} style={this.state.style1}>
							<img src={this.state.setImage} alt="" width="100%" />
						</figure>
						{/* <img className="side-img" src={this.state.setImage} alt="" width="100%" /> */}
					</div>
				</div>
				<div className="lastDes" style={{ width: '33%', textTransform: 'capitalize' }}>
					<h1 style={{ paddingTop: '10%' }}>{this.state.product.name}</h1>
					<div className="sapan-style">
						<span style={{ paddingTop: '10%' }}>
							SKU:<b>{this.state.product.sku}</b>
						</span>
						<span style={{ paddingTop: '10%' }}>
							CATEGORY:<b>{this.state.product.categoryName}</b>
						</span>
						<span style={{ paddingTop: '10%' }}>
							DESCRIPTION:<b>{this.state.product.description}</b>
						</span>
					</div>
				</div>
				</div>
			</div>
		);
	}
}
export default ProductView;
